@import "variables";
@import "../../../../node_modules/bootstrap/scss/functions";
@import "../../../../node_modules/bootstrap/scss/mixins";
@import "../../../../node_modules/bootstrap/scss/variables";
@import "fonts";

// Variables
$mobile-menu-until: md;
$mobile-menu-expands: breakpoint-next($mobile-menu-until, $grid-breakpoints);
$logo-height-collapsed: 50px;
$logo-height-expanded: 80px;

// General

BODY::before {
  display: block;
  content: '';
  height: 75px;
}

.container,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  padding: 0 1.5rem;
}

@media (min-width: 1400px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1320px;
  }
}

body {
  @include media-breakpoint-up(md) {
    background: url('../../images/background.png') no-repeat;
    background-size: 100%;
  }
}

@include media-breakpoint-down(sm) {
  .container-sm-fluid {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    margin-right: auto;
    margin-left: auto;
    max-width: none;
    overflow: hidden;
  }
}

// Nav
@media (min-width: 1400px) {
  .navbar {
    .digitization-dropdown {
      margin-left: 13%;
    }

    .courses-dropdown {
      margin-left: 24.5%;
    }

    .consulting-dropdown {
      margin-left: 33.5%;
    }
  }
}

@media (max-width: 1399px) {
  .navbar {
    .digitization-dropdown {
      margin-left: 15%;
    }

    .courses-dropdown {
      margin-left: 28.5%;
    }

    .consulting-dropdown {
      margin-left: 39.5%;
    }
  }
}

@media (max-width: 1199px) {
  .navbar {
    .digitization-dropdown {
      margin-left: 18.5%;
    }

    .courses-dropdown {
      margin-left: 34%;
    }

    .consulting-dropdown {
      margin-left: 47.5%;
    }
  }
}

@media (min-width: 992px) {
  .navbar {
    .dropdown-menu {
      margin-top: -2px;
      box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    }
  }
}

@media (max-width: 991px) {
  .navbar {
    .digitization-dropdown, .courses-dropdown, .consulting-dropdown {
      margin-left: 0;
    }

    .dropdown-menu {
      img {
        height: 25px;
      }
    }
  }
}

.navbar {
  p {
    font-family: "HK Grotesk", sans-serif;
    font-style: normal;
    font-weight: 300;
  }

  .dropdown-menu {
    border: 0;
  }

  .dropdown-item.active {
    background: transparent;

    p {
      color: #22303F;
      font-weight: 400;
    }
  }

  .dropdown-item:hover {
    background: transparent;
    p {
      color: #22303F;
      font-weight: 400;
    }
  }
}

// Footer
footer {
  background-color: #22303F;

  p {
    font-family: "HK Grotesk", sans-serif;
    font-style: normal;
    font-weight: 300;
    color: #E0E3E8;
  }

  a {
    font-family: "HK Grotesk", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 0.93rem;
    color: #E0E3E8;
    margin-bottom: 4px;
  }

  a:hover {
    color: $white;
    font-weight: bolder;
  }
}

// Inputs
.form-control-fake-lg {
  height: calc(2.875rem + 2px);
  line-height: 1.5;
  border-radius: 25px;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  background-color: $light;

  &::placeholder {
    text-transform: uppercase;
  }
  &.newsletter-input {
    padding: 0.5rem 1rem !important;
  }
}

textarea.form-control-fake-lg {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.form-group {
  position: relative;

  [required] {
    background-repeat: no-repeat;
    background-position: center right calc(3.25rem / 4);
    background-size: 0.75rem 0.75rem;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 8 8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m1.1564 1.2701 5.3484 5.4574' fill='none' stroke='%232ba8df' stroke-width='1px'/%3E%3Cpath d='m1.1569 6.7349 5.3549-5.4648' fill='none' stroke='%232ba8df' stroke-width='1px'/%3E%3Cpath d='m3.8215 0.38863 0.0094787 7.09' fill='none' stroke='%232ba8df' stroke-width='1px'/%3E%3Cpath d='m7.4592 3.9939-7.09 0.00948' fill='none' stroke='%232ba8df' stroke-width='1px'/%3E%3C/svg%3E");
  }
  textarea[required] {
    background-position: top 1em right calc(3.25rem / 4);
  }
}

// Buttons
.btn {
  &.btn-lg {
    font-weight: 900;

    @include media-breakpoint-up(sm) {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
  &.btn-dots::after {
    content: '...';
    position: relative;
    top: -0.3rem;
    margin-left: 0.5rem;
  }
}

@include media-breakpoint-only(xs) {
  .btn-lg {
    @include button-size($input-btn-padding-y, $input-btn-padding-x, $font-size-base + 0.5rem, $line-height-base, $btn-border-radius + 0.5rem);
  }
}

// Typography
@for $i from 1 through 6 {
  h#{$i}.styled, .rte h#{$i} {
    font-weight: 900;
    text-align: center;

    @include media-breakpoint-up(md) {
      text-align: left;

      &::before {
        content: " ";
        display: inline-block;
        width: 0.9rem;
        background: $green;
        border-left: 0.3rem solid $light-green;
        border-right: 0.3rem solid $primary;
        height: 0.8em;
        margin-right: 0.5rem;
      }
    }
  }
}

@include media-breakpoint-only(xs) { // only 90% font size on small screens
  h1, .h1 {
    @include font-size($h1-font-size / 10 * 9);
  }
  h2, .h2 {
    @include font-size($h2-font-size / 10 * 9);
  }
  h3, .h3 {
    @include font-size($h3-font-size / 10 * 9);
  }
  h4, .h4 {
    @include font-size($h4-font-size / 10 * 9);
  }
}

.font-weight-super-bold {
  font-weight: 900;
}

.larger, .rte p {
  font-size: 1.25rem;
  color: $gray-600;

  @include media-breakpoint-only(xs) {
    font-size: 1.15rem !important;

    &.xs-not-larger {
      font-size: 1rem;
    }
  }
}

.rte {
  font-size: 1.25rem;
  color: $gray-600;

  strong, b {
    font-weight: 900;
  }
  @for $i from 1 through 6 {
    h#{$i} {
      color: $black;
      margin-bottom: 1.5rem;
      margin-top: 2rem;
    }
  }
  ul, ol {
    list-style-type: none !important;
    padding: 0;
    border-bottom: 1px solid $primary;
    margin-bottom: 0;

    @include media-breakpoint-up(md) {
      border-bottom: none;
    }

    li {
      border-top: 1px solid $primary;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;

      @include media-breakpoint-up(md) {
        margin-bottom: 0.5rem;
        padding-top: 0;
        position: relative;
        padding-left: 3rem;
        border-top: none;
        border-bottom: none;

        &::before {
          content: "\2022";
          display: inline-block;
          color: $primary;
          position: absolute;
          left: 1.5rem;
          font-size: 3rem;
          line-height: 2.1rem;
        }
      }

      > p {
        margin-bottom: 0;
      }
    }
  }

  &.rte-smaller {
    ul {
      li {
        padding-left: 2rem;
        padding-bottom: 0;

        @include media-breakpoint-up(md) {
          &::before {
            content: "\2022";
            display: inline-block;
            color: $primary;
            position: absolute;
            left: 1rem;
            font-size: 2rem;
            line-height: 1.9rem;
          }
        }
      }
    }
  }
}

// Pagination
.pagination {
  .page-item {
    margin-right: 1rem;
  }
}

// Gallery
.entity-gallery {
  .gallery-item {
    display: block;
    width: 33.333%;
    position: relative;

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      justify-content: center;
      align-items: center;
      color: #FFF;
      opacity: 0;
      display: flex;
    }
    img {
      filter: grayscale(100%);
    }
    @include media-breakpoint-only(xs) {
      width: 100%;
    }
    @include media-breakpoint-only(sm) {
      width: 50%;
    }
    &:hover {
      .overlay {
        opacity: 1;
      }
      img {
        filter: grayscale(0%);
      }
    }
  }
}

#entity-gallery-modal {
  text-align: center;

  .modal-dialog {
    display: inline-block;

    .modal-body {
      padding: 0;
    }
  }
}

// Global mobile overrides
@include media-breakpoint-only(xs) {
  .slider-item {
    text-align: center;
  }
}

// Responsive video
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  width: 100%;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.fluid-embed {
  position: relative;
  padding-bottom: 52.77%;
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

/* Cookie banner */
.cookie-banner {
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  padding: 10px 32px;
  background: #000;
  color: #fff;
  text-align: center;
  z-index: 999999;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  a {
    color: #fff;
    font-weight: 600;
  }
  .cookie-close {
    margin-left: 32px;
    cursor: pointer;
  }
}

body:not(.cookie-banner-active) {
  padding-bottom: 0 !important;
}

// Events
.event{
  &.event-1{
    border: 1px solid $primary;

    .event-featured-image{
      .overlay{
        background: rgba($primary, 0.4);
      }
    }
  }
  &.event-2{
    border: 1px solid $dark-green;

    .event-featured-image{
      .overlay{
        background: rgba($dark-green, 0.4);
      }
    }
  }
  &.event-3{
    border: 1px solid $green;

    .event-featured-image{
      .overlay{
        background: rgba($green, 0.4);
      }
    }
  }
  &.event-4{
    border: 1px solid $light-green;

    .event-featured-image{
      .overlay{
        background: rgba($light-green, 0.4);
      }
    }
  }

  border-left-width: 0.75rem !important;

  .event-featured-image {
    position: relative;
    display: block;

    .overlay {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($primary, 0.8);
    }
  }

  @include media-breakpoint-down(sm){
    border-left-width: 1px !important;
    border-top-width: 0.75rem !important;
  }
}