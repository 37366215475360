// Fonts
$font-family-base: 'Roboto', sans-serif;

// Colors
$light-blue: #c0e5f5;
$light-green: #d8df26;
$dark-green: #00a59b;
$green: #16b252;
$green-alt: #00b175;
$primary: #2ba8df;
$secondary: $dark-green;

$theme-colors: (
  "light-blue": $light-blue,
  "dark-green": $dark-green,
  "green": $green,
  "green-alt": $green-alt,
  "light-green": $light-green,
);

// Grid
$grid-columns: 24;

// Inputs & Buttons
$input-border-radius: 1.2rem;
$input-border-radius-lg: 1.5rem;
$input-padding-x-lg: 2rem;
$btn-border-radius: 1.2rem;
$btn-border-radius-lg: 1.75rem;
$btn-border-width: 2px;
$btn-font-size-lg: 1.5rem;

// Spacing
$spacer: 1rem;
$spacers: (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  '2-3': ($spacer * .75),
  3: $spacer,
  '3-4': ($spacer * 1.25),
  4: ($spacer * 1.5),
  '4-5': ($spacer * 2),
  5: ($spacer * 3)
);

// Other
$link-hover-decoration: none;
$border-radius: 0;
$border-radius-lg: 0;
$border-radius-sm: 0;

$bubble-opacity: 0.9;

$custom-file-text: (
  en: "Browse",
  ro: "Incarca"
);
